namespace Shared.EditorTemplates.SelectListCheckboxes
{
    const setupControls = () =>
    {
        var $selectListCheckboxes = $("input[data-editortype='SelectListCheckbox']:not(.selectListCheckboxClickChangeSetup)");
        $selectListCheckboxes.on("click", selectListCheckBoxClick);
        $selectListCheckboxes.on("change", selectListCheckBoxChange);
        $selectListCheckboxes.addClass("selectListCheckboxClickChangeSetup");
    }

    const documentAJAXComplete = () =>
    {
        setupControls();
    }

    const documentReady = () =>
    {
        setupControls();
    }

    const selectListCheckBoxClick = (event: JQueryEventObject): void =>
    {
        uncheckOtherBoxes(event.currentTarget);
    }

    const selectListCheckBoxChange = (event: JQueryEventObject): void =>
    {
        uncheckOtherBoxes(event.currentTarget);
    }

    const uncheckOtherBoxes = (element: Element): void =>
    {
        var $element: JQuery = $(element);

        var checked = $element.is(':checked');

        if (checked)
        {
            var value = $element.val();

            var name = $element.attr('name');

            var $checkboxesToUncheckList = $(`input[data-editortype='SelectListCheckbox'][name='${name}'][value!='${value}']:checked`);

            if ($checkboxesToUncheckList.length > 0)
            {
                $checkboxesToUncheckList.prop('checked', false);
                $checkboxesToUncheckList.trigger("change");
            }
        }
    }

    $(document).ready(documentReady);
    $(document).ajaxComplete(documentAJAXComplete);
}